import { post } from "./base";

const API_SERVICE_PUBLICO_DOWNLOAD_CSV = "/api/Prueba/downloadCSV";

export default {

  getReportePacienteProvincia: (filtro = "", fecha_corte, dia_inicio, dia_final, titulo = "") => {
    const request = {
      _type: 1,
      _table: "paciente_provincia",
      _valor: "PROVINCIA",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "dw_web_data_reporte_padron_provincia_reporte"
              : "dw_web_data_reporte_padron_provincia_reporte_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },

  getReportePacienteDistrito: (provincia, filtro = "", fecha_corte, dia_inicio, dia_final, titulo = "") => {
    const request = {
      _type: 1,
      _table: "paciente_distrito",
      _valor: "DISTRITO",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "dw_web_data_reporte_padron_distrito_reporte"
              : "dw_web_data_reporte_padron_distrito_reporte_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },

  
  getReporteEstablecimientoo: (
    provincia,
    distrito,
    filtro = "",
    fecha_corte,
    dia_inicio,
    dia_final,
    titulo
  ) => {
    const request = {
      _type: 1,
      _table: "paciente_establecimiento",
      _valor: "ESTABLECIMIENTO",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "dw_web_data_reporte_padron_establecimiento_reporte"
              : "dw_web_data_reporte_padron_establecimiento_reporte_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },

  getReportePacienteProvinciaGestante: (filtro = "", fecha_inicio, fecha_fin, titulo = "") => {
    const request = {
      _type: 1,
      _table: "paciente_provincia",
      _valor: "PROVINCIA",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value: "dw_web_data_reporte_padron_provincia_gestantes_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },

  getReportePacienteDistritoGestante: (provincia, filtro = "", fecha_inicio, fecha_fin, titulo = "") => {
    const request = {
      _type: 1,
      _table: "paciente_distrito",
      _valor: "DISTRITO",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value: "dw_web_data_reporte_padron_distrito_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },
  
  getReporteEstablecimientooGestante: (
    provincia,
    distrito,
    filtro = "",
    fecha_inicio,
    fecha_fin,
    titulo = ""
  ) => {
    const request = {
      _type: 1,
      _table: "paciente_establecimiento",
      _valor: "ESTABLECIMIENTO",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value: "dw_web_data_reporte_padron_establecimiento_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },
};
